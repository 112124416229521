export class HttpResponseError extends Error {
    /**
     * @param {Response} response
     * @param {object|string} errorBody
     * @param {any} parameters
     */
    constructor(response, errorBody, ...parameters) {
        super(...parameters);
        this.status = response.status;
        this.statusText = response.statusText;
        this.errorBody = errorBody;
        this.message = errorBody?.message || response.statusText;
    }
}
