import {HttpResponseError} from './userDefinedExceptions.js';

/**
 * @typedef {import('./httpClientDef').HttpClient} HttpClient
 */

/**
 * Wrapper for the Fetch API which passes custom headers and parses response data for us.
 * @param {string} url
 * @param {string} method GET|POST|PUT|DELETE
 * @param {object|FormData|null} [payload] data to be sent
 * @param {object} [customRequestOptions] custom request options passed for special cases. This can be used for
 * overwriting headers, CORS and even the body. Because all of these properties are part of request options.
 * @param {boolean} [automaticallySetHeaders] in some cases we would like to disable default headers
 * @returns {Promise<object>} returns JSON response in most cases
 * @throws {HttpResponseError}
 */
async function customFetch(url, method, payload = {}, customRequestOptions = {}, automaticallySetHeaders = true) {
    const headers = automaticallySetHeaders
        ? Object.assign({}, getDefaultHeaders(payload), customRequestOptions.headers)
        : customRequestOptions.headers;

    const requestOptions = Object.assign(
        {},
        {method, headers, body: prepareBodyToSend(method, payload, headers)},
        customRequestOptions,
        {headers}
    );
    const response = await fetch(url, requestOptions);

    if (response.ok) {
        return parseResponse(response);
    }

    throw new HttpResponseError(response, await parseResponse(response));
}

/**
 * Returns default headers
 * @param {object|FormData|null} payload data to be sent
 * @returns {object}
 */
function getDefaultHeaders(payload) {
    const headers = {
        Accept: 'application/json, text/javascript, */*',
        // @ts-ignore
        'X-CSRF-TOKEN': window.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
    };
    // We only use json or FormData, so if not FormData use json header
    if (payload && !(payload instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
    }
    return headers;
}

/**
 * Parses the response based on headers
 * @param {Response} response fetch response
 * @returns {Promise<object|string|null|Response>}
 */
async function parseResponse(response) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return response.json();
    }
    if (contentType && contentType.includes('text/')) {
        return response.text();
    }

    return response;
}

/**
 * Converts data into json if type json, also deletes body if GET|DELETE call
 * @param {string} method fetch method like Get, Post
 * @param {object|FormData|null} payload data to be sent
 * @param {object} headers headers to be sent
 * @returns {undefined|object|FormData}
 */
function prepareBodyToSend(method, payload, headers) {
    if (method === 'GET' || (method === 'DELETE' && Object.keys(payload).length === 0)) return;

    if (headers['Content-Type'] === 'application/json') return JSON.stringify(payload);

    return payload;
}

/**
 * @returns {HttpClient}
 */
export const getHttpClient = () => ({
    automaticallySetHeaders: true,
    disableDefaultHeaders() {
        this.automaticallySetHeaders = false;
        return this;
    },
    requestOptions: {},
    setRequestOptions(requestOptions) {
        this.requestOptions = requestOptions;
        return this;
    },
    /**
     * @param {string} url
     * @returns {Promise<object>} returns JSON response in most cases
     * @throws {HttpResponseError}
     */
    get(url) {
        return customFetch(url, 'GET', null, this.requestOptions, this.automaticallySetHeaders);
    },
    /**
     * @param {string} url
     * @param {object|FormData|null} payload data to be sent
     * @returns {Promise<object>} returns JSON response in most cases
     * @throws {HttpResponseError}
     */
    post(url, payload) {
        return customFetch(url, 'POST', payload, this.requestOptions, this.automaticallySetHeaders);
    },
    /**
     * @param {string} url
     * @param {object|FormData|null} payload data to be sent
     * @returns {Promise<object>} returns JSON response in most cases
     * @throws {HttpResponseError}
     */
    put(url, payload) {
        return customFetch(url, 'PUT', payload, this.requestOptions, this.automaticallySetHeaders);
    },
    /**
     * @param {string} url
     * @param {object|FormData|null} payload data to be sent
     * @returns {Promise<object>} returns JSON response in most cases
     * @throws {HttpResponseError}
     */
    delete(url, payload) {
        return customFetch(url, 'DELETE', payload, this.requestOptions, this.automaticallySetHeaders);
    },
});
